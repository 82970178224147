import { Card, Checkbox, Flex, Input, Space, Text, Title } from "@mantine/core";
import styled from "styled-components";
import { BILL_SUMMARY } from "../../../constants";
import { useAppData } from "../../../contexts/AppContext";
import { Step } from "../../../constants/types";
import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { BatchRuleActionType } from "../../../constants/enums";

function BillingAddress() {
  const { appFlow, setData, data, order, setDisableContinue } = useAppData();

  const form = useForm({
    initialValues: {
      ...(data.addresses.bothSame
        ? data.addresses.shippingAddress
        : data.addresses.billingAddress),
    },
    validate: {
      addressLine1: (value) =>
        value === "" ? "Address Line 1 required" : null,
      // addressLine2: (value) =>
      //   value === "" ? "Address Line 2 required" : null,
      city: (value) => (value === "" ? "City required" : null),
      state: (value) => (value === "" ? "State required" : null),
      zipCode: (value) => (value === "" ? "Zip Code required" : null),
    },
  });

  useEffect(() => {
    setData((data) => ({
      ...data,
      addresses: {
        ...data.addresses,
        billingAddress: {
          ...form.values,
        },
      },
    }));
  }, [form.values]);

  useEffect(() => {
    if (data.addresses.bothSame) {
      setDisableContinue({
        [Step.BillingAddress]: false,
      });
    } else {
      if (form.isValid()) {
        setDisableContinue({
          [Step.BillingAddress]: false,
        });
      } else {
        setDisableContinue({
          [Step.BillingAddress]: true,
        });
      }
    }
  }, [data, form.values]);

  useEffect(() => {
    form.setValues({
      ...(data.addresses.bothSame
        ? data.addresses.shippingAddress
        : data.addresses.billingAddress),
    });
  }, [appFlow.currentStep]);

  return (
    <Flex direction={"column"} className="my-billing-holder">
      <StyledTitle mb={10}>My Prescriptions</StyledTitle>
      <InnerContainer>
        <Flex direction={"column"} gap={15} flex={2}>
          <Card w={"100%"}>
            <Text fw={700} mb={10}>
              Summary
            </Text>
            <Flex direction={"column"} gap={10}>
              {data.rushOrder ? (
                <Flex justify={"space-between"}>
                  <Text>Rush order</Text>
                  <Text style={{ textAlign: "left" }}>$25</Text>
                </Flex>
              ) : null}
              <Flex justify={"space-between"}>
                <Text>Medicines</Text>
                <Text style={{ textAlign: "left" }}>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    data?.medications
                      ?.filter(
                        (med) => !med.metadata![BatchRuleActionType.NoPayment]
                      )
                      ?.reduce(
                        (acc, med: any) =>
                          acc + parseFloat(med.price.replace(/^\D+/g, "")),
                        0
                      ) || 0
                  )}
                </Text>
              </Flex>

              {BILL_SUMMARY.map((summary) => (
                <Flex justify={"space-between"} key={summary.name}>
                  <Text>{summary.name}</Text>
                  <Text style={{ textAlign: "left" }}>
                    {summary.price ? `$${summary.price}` : "TBD"}
                  </Text>
                </Flex>
              ))}
              {data.shippingOption ? (
                <Flex justify={"space-between"}>
                  <Text>Delivery</Text>
                  <Text style={{ textAlign: "left" }}>
                    $
                    {data.shippingOption &&
                    !isNaN(parseFloat(data.shippingOption))
                      ? parseFloat(data.shippingOption)
                      : 0}
                  </Text>
                </Flex>
              ) : null}
              <Flex gap={8} style={{ alignSelf: "flex-end" }}>
                <Text fw={500}>Total</Text>
                <Text fw={500}>${data?.totalAmount?.toFixed(2)}</Text>
              </Flex>
            </Flex>
          </Card>
        </Flex>
        <Flex
          direction={"column"}
          // className="responsive-card"
          flex={4}
          gap={15}>
          <Card w={"100%"} mih={"100%"}>
            <Space h="sm" />
            <Text fw={500}>Billing Address</Text>

            <Space h="sm" />

            <Input
              readOnly={data.addresses.bothSame}
              maw={"500px"}
              radius="md"
              size="md"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "2px",
                },
              }}
              placeholder="Street Address"
              {...form.getInputProps("addressLine1")}
            />
            <Space h="xs" />

            <Input
              readOnly={data.addresses.bothSame}
              maw={"500px"}
              radius="md"
              size="md"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "2px",
                },
              }}
              placeholder="Street address line 2"
              {...form.getInputProps("addressLine2")}
            />
            <Space h="xs" />

            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "sm", sm: "xs" }}>
              <Input
                readOnly={data.addresses.bothSame}
                maw={"500px"}
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="City"
                {...form.getInputProps("city")}
              />
              <Input
                readOnly={data.addresses.bothSame}
                maw={"500px"}
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="State / Province"
                {...form.getInputProps("state")}
              />
            </Flex>
            <Space h="xs" />
            <Input
              readOnly={data.addresses.bothSame}
              maw={"250px"}
              radius="md"
              size="md"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "2px",
                },
              }}
              placeholder="Postal / Zip Code"
              type="number"
              value={form.values.zipCode || ""}
              onChange={(event) => {
                const sanitizedValue = event.currentTarget.value.replace(/\D/g, '').slice(0, 7);
                form.setFieldValue("zipCode", sanitizedValue || "");
              }}
              onBlur={() => form.validateField("zipCode")}
            />
            <Space h="md" />

            <Checkbox
              checked={data.addresses.bothSame}
              onChange={(e) => {
                let checked = e.target.checked;
                setData((data) => ({
                  ...data,
                  addresses: {
                    ...data.addresses,
                    bothSame: checked,
                  },
                }));

                !checked
                  ? form.setValues({
                      email: "",
                      addressLine1: "",
                      addressLine2: "",
                      city: "",
                      state: "",
                      zipCode: "",
                    })
                  : form.setValues({ ...data.addresses.shippingAddress });
              }}
              label={<Text>Same as shipping address</Text>}
            />
          </Card>
        </Flex>
      </InnerContainer>
    </Flex>
  );
}

export default BillingAddress;

const InnerContainer = styled.div`
  gap: 15px;
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
  .my-billing-holder{
    width: 100%;
  }
`;

const StyledTitle = styled(Title)`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
`;

const Subtitle1 = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.011em;
`;
