import {
  Button,
  Card,
  Flex,
  Input,
  Modal,
  Radio,
  Space,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import styled from "styled-components";
import MedicationCard from "../components/MedicationCard";
import { useAppData } from "../../../contexts/AppContext";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { BatchRuleActionType } from "../../../constants/enums";
import {paymentProfileObject, Step} from "../../../constants/types";
import { BILL_SUMMARY } from "../../../constants";
import axios from "axios";
import {userInfo} from "node:os";
import { useDisclosure } from "@mantine/hooks";
import {IconTrash} from "@tabler/icons-react";
import {notifications} from "@mantine/notifications";
import {modals} from "@mantine/modals";
import assets from "../../../constants/assets";

function OrderSummary() {
  const { data, setData, user,order,setDisableContinue,triggerContinueAction } = useAppData();
  const form = useForm({
    initialValues: {
      ...data.paymentInfo,
      expiryDate: "",
    },
    validate: {
      creditCardNumber: (value) =>
        value === "" ? "Credit Card Number required" : null,
      expiryDate: (value) => (value === "" ? "Expiry Date required" : null),
      securityCode: (value) => (value === "" ? "Security Code required" : null),
      firstName: (value) => (value === "" ? "First Name required" : null),
      lastName: (value) => (value === "" ? "Last Name required" : null),
    },
  });


  const [cardDetails, setCardDetails] = useState([]);
  const [selectedCard, setSelectedCard] = useState('new-card');
  const [mode, setMode] = useState('NEW_CARD');
  const [cvv, setCvv] = useState('');
  const [loader, setLoader] = useState(false);
  const [last4Digits, setLast4Digits] = useState('');
  const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_ENV === 'dev'
      ? 'http://127.0.0.1:5001/fir-34309/us-central1/app' : 'https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app');
  useEffect(() => {
    // Getting all linked cards if any
    if(user?.id || user?.customerProfileId) {
      setLoader(true);
      axios
          .post(`${baseUrl}/getAllLinkedCards`, {customerProfileId: user?.customerProfileId,uid:user?.id})
          .then((response: any) => {
            if (response?.data?.success) {
              setCardDetails(response.data.paymentProfiles);
            } else {
              console.info("Unexpected response structure:", response);
            }
          })
          .catch((error) => {
            console.info("Error fetching linked cards:", error);
          }).finally(() => {
            setLoader(false);
          });
    }
    setDisableContinue({[Step.OrderSummary]: true,});
    let item = document.getElementById('new_card_holder');
    if(item){
      item.style.display = 'none';
    }

  }, []);
  useEffect(() => {
    if (cardDetails.length >= 1) {
      setSelectedCard((cardDetails[0] as any).customerPaymentProfileId);
      setMode('EXISTING_PROFILE_ID');
      setDisableContinue({[Step.OrderSummary]: true,});
    }
    let item = document.getElementById('new_card_holder');
    if (cardDetails.length === 0) {
      setSelectedCard('new-card');
      setMode('NEW_CARD');
      if(item){
        item.style.display = 'block';
      }
    }else{
      if(item){
        item.style.display = 'none';
      }
      setCvv('');
      document.getElementsByClassName('cvv_holder')[0]?.classList.remove('cvv_holder_show');
    }
  }, [cardDetails]);
  function refixCardNumber(cd_number:string){
    return 'Card ending in '+cd_number.replaceAll("X","");
  }

  function camelCase(name: string) {
    return name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }
  const handleDivClick = (value:any) => {
    setSelectedCard(value);
    const cardHolder = document.getElementById('new_card_holder');
    if (cardHolder) {
      cardHolder.style.display = value === 'new-card' ? 'block' : 'none';
    }
    //debugger;
    if(value === 'new-card'){
      if(cardDetails.length === 0){
        setMode('NEW_CARD');
      }
      if(cardDetails.length >= 1){
        setMode('ADD_ANOTHER_CARD');
        document.getElementsByClassName('cvv_holder')[0]?.classList.remove('cvv_holder_show');
      }
    }else{
      setMode('EXISTING_PROFILE_ID');
    }
    console.log(mode);
  }
  useEffect(() => {
    console.log('Card Details changed:', cardDetails);
    console.log('Selected Card:', selectedCard);
    let rms = document.getElementsByClassName('cvv_holder');
    for (let i = 0; i < rms.length; i++) {
      rms[i].classList.remove('cvv_holder_show');
    }
    setCvv('');
    setLast4Digits('');
    let cc_details = (cardDetails.find((card: any) => card.customerPaymentProfileId === selectedCard) as any)?.payment.creditCard.cardNumber;
    if (cc_details) {
      cc_details = cc_details?.slice(-4);
      setLast4Digits(cc_details);
    }
    if(selectedCard){
      setDisableContinue({[Step.OrderSummary]: true,});
      document.getElementsByClassName('cvv_holder_'+selectedCard)[0]?.classList.add('cvv_holder_show');
    }
  }, [cardDetails, selectedCard]);
  const handleDeleteCard = (customerProfileId:any,customerPaymentProfileId: any) => {
    //confirmation in matine
    const openModal = () => modals.openConfirmModal({
      title: 'Please confirm your action',
      size: 'sm',
      radius: 'md',
      withCloseButton: false,
      children: (
          <Text size="sm">
           Are u sure about deleting this card?
          </Text>
      ),
      confirmProps: { px: 'sm' },
      cancelProps: { px: 'sm' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        setLoader(true);
        axios
            .post(`${baseUrl}/deletePaymentProfile`, {customerProfileId:customerProfileId,customerPaymentProfileId:customerPaymentProfileId})
            .then((response: any) => {
              if (response?.data?.success) {
                let cd = cardDetails.filter((card: any) => card.customerPaymentProfileId !== customerPaymentProfileId);
                setCardDetails(cd);
                console.log('After delete - Mode:', mode, 'Selected Card:', selectedCard);
              } else {
                console.error("Unexpected response structure:", response);
              }
            })
            .catch((error) => {
              console.error("Error fetching linked cards:", error);
            }).finally(() => {
          setLoader(false);
        });
      },
    });

    openModal();
  }
  useEffect(() => {
    if(selectedCard === 'new-card') {
      const [expiryMonth, expiryYear] = form?.values?.expiryDate?.split("/");
      setData((data) => ({
        ...data,
        paymentInfo: {
          ...form.values,
          expiryMonth,
          expiryYear,
        },
        mode: mode,
      }));

      setDisableContinue({
        [Step.OrderSummary]: !form.isValid(),
      });
      if (form.isTouched()) {
        form.validate();
      }
    }
  }, [form.values,mode]);


  function approveAndPay(todo: any): void {
    debugger;
    if (todo == "triggerPayment") {
      if(cvv === '') {
        notifications.show({
          title: "Cvv required",
          message: "Please enter the CVV number",
          color: "red",
          radius: "md",
          autoClose: 10000,
          closeButton: true
        });
        //setDisableContinue({[Step.OrderSummary]: false,});
        return;
      }
      //setDisableContinue({[Step.OrderSummary]: false,}); // no need to disable continue button
      debugger;
      setData((data) => ({
        ...data,
        paymentInfo: {
          ...form.values,
          expiryMonth: '',
          expiryYear: '',
        },
        mode: mode,
        customerPaymentProfileId: selectedCard,
        cvv: cvv,
        cardLast4Digits: last4Digits,
      }));
      triggerContinueAction({do:"triggerPayment"}); // this code will trigger the charge action
    }
  }

  return (
    <Flex className="responsive-card my-order-summary-holder" direction={"column"} miw={"60vw"}>
      <StyledTitle mb={15}>Order Summary</StyledTitle>
      <InnerContainer>
        <Flex direction={"column"} gap={15} flex={2} w={"100%"}>
          <Card>
            <Text style={{ fontSize: 18 }} mb={10}>
              Summary
            </Text>
            <MedicationCard editable={false} />
            {data.rushOrder ? (
              <Flex justify={"space-between"}>
                <Text>Rush order</Text>
                <Text style={{ textAlign: "left" }}>$25</Text>
              </Flex>
            ) : null}

            <Flex justify={"space-between"}>
              <Text>Medicines</Text>
              <Text style={{ textAlign: "left" }}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(
                  data?.medications
                    ?.filter(
                      (med) => !med.metadata![BatchRuleActionType.NoPayment]
                    )
                    ?.reduce(
                      (acc, med) =>
                        acc + parseFloat(med.price.replace(/^\D+/g, "")),
                      0
                    ) || 0
                )}
              </Text>
            </Flex>

            <Space h={"xs"} />
            {BILL_SUMMARY.map((summary) => (
              <Flex justify={"space-between"} key={summary.name}>
                <Text>{summary.name}</Text>
                <Text style={{ textAlign: "left" }}>
                  {summary.price ? `$${summary.price}` : "TBD"}
                </Text>
              </Flex>
            ))}

            <Space h={"xs"} />
            {data.shippingOption ? (
              <Flex justify={"space-between"}>
                <Text>Delivery</Text>
                <Text style={{ textAlign: "left" }}>
                  $
                  {data.shippingOption &&
                  !isNaN(parseFloat(data.shippingOption))
                    ? parseFloat(data.shippingOption)
                    : 0}
                </Text>
              </Flex>
            ) : null}
            <Space h={"xs"} />
            {/* <Flex justify={"space-between"}>
              <Text>Subtotal</Text>
              <Text>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(data.totalAmount || 0)}
              </Text>
            </Flex> */}

            <Space h={"xs"} />
            <Flex gap={8} style={{ alignSelf: "flex-end" }}>
              <Text fw={500}>Total</Text>
              <Text fw={500}>${data.totalAmount?.toFixed(2)}</Text>
            </Flex>
          </Card>
        </Flex>
        <Flex direction={"column"} flex={4} gap={15} className={`my-os-holder-inner ${loader ? 'main_payment_cnt' : ''}`}>
          {/* {order?.metadata![BatchRuleActionType.NoPayment] ? null : ( */}
          {/* add code below for listing credit card info */}
          {cardDetails.length > 0 ? (
            <Card style={{display:"inline-table"}} w={"100%"}>
              <Text fw={500}>Linked Cards</Text>
              <Space h="xs" />
                  <>
                    {cardDetails.map((card: paymentProfileObject, index) => (
                        <div className="card-container" key={index}
                             onClick={() => handleDivClick(card.customerPaymentProfileId)} >
                          <div className="card-main">
                          <div className="card-details">
                            <div className="card-radio">
                              {/* Radio for existing cards */}
                              <Radio
                                  value={card.customerPaymentProfileId}
                                  label=""
                                  name="card"
                                  style={{ margin: 0 }}
                                  checked={selectedCard === card.customerPaymentProfileId}
                                  onChange={(event) => handleDivClick(event.currentTarget.value)}
                              />
                            </div>
                            <div className="card-number">{refixCardNumber(card.payment.creditCard.cardNumber)}</div>
                            <img src={assets.images.creditCard} alt="credit-card" />
                          </div>
                          <div className="card-billing-name">
                            {camelCase(card.billTo.firstName + ' ' + card.billTo.lastName)}
                          </div>
                          </div>
                          <div className="card-actions">
                            <div className={`cvv_holder cvv_holder_${card.customerPaymentProfileId}`}>
                              <div className={'cvv_sub_holder'}>
                                <TextInput
                                    placeholder="Security Code"
                                    value={cvv}
                                    disabled={selectedCard != card.customerPaymentProfileId}
                                    onChange={(e) => setCvv(e.target.value)}
                                />
                                <Button disabled={selectedCard != card.customerPaymentProfileId} className={'pay_btn btn_hght'}
                                        onClick={() => {
                                          approveAndPay('triggerPayment');
                                        }}>Pay</Button>
                              </div>
                              <div>
                                <Button
                                    variant="outline"
                                    size="xs"
                                    className={'btn_unlink btn_hght'}
                                    disabled={selectedCard != card.customerPaymentProfileId}
                                    onClick={() => handleDeleteCard(user?.customerProfileId, card.customerPaymentProfileId)}
                                >
                                  <IconTrash />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                    ))}
                    {/* Additional radio for "New Card" */}
                    <div className="card-container" key={cardDetails.length + 1}
                         onClick={() => handleDivClick('new-card')}>
                      <div className="card-details">
                        <div className="card-radio">
                          <Radio
                              value="new-card"
                              label="New Card"
                              name="card"
                              style={{ margin: 0 }}
                              checked={mode === 'NEW_CARD' || mode==='ADD_ANOTHER_CARD'}
                              onChange={(event) => handleDivClick(event.currentTarget.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
            </Card>
          ) : ('')}
          <Card style={{display:"inline-table"}} w={"100%"} id={'new_card_holder'}>
            <Text fw={500}>Credit Card</Text>
            <Space h="xs"/>
            <Flex
                direction={{base: "column", sm: "row"}}
                gap={{base: "lg", sm: "lg"}}>
              <TextInput
                  radius="md"
                  size="md"
                  styles={{
                    input: {
                      borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="First Name"
                {...form.getInputProps("firstName")}
              />
              <TextInput
                maw={"800px"}
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Last Name"
                {...form.getInputProps("lastName")}
              />
            </Flex>
            <Space h="md" />

            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "lg", sm: "lg" }}>
              <TextInput
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Credit Card Number"
                {...form.getInputProps("creditCardNumber")}
              />
              <TextInput
                maw={"800px"}
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Security Code"
                {...form.getInputProps("securityCode")}
              />
            </Flex>
            <Space h="md" />
            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "lg", sm: "lg" }}>
              <ExpirationDateInput
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Expiration Date (MM/YY)"
                {...form.getInputProps("expiryDate")}
              />
              <Input
                maw={"800px"}
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Zip Code"
                {...form.getInputProps("zipCode")}
              />
            </Flex>

            <Space h="xl" />
          </Card>
          {loader && <div className={'loader_ico'}><img src={'./assets/images/loader.gif'}/> </div>}
        </Flex>
      </InnerContainer>
    </Flex>
  );
}

export default OrderSummary;

const InnerContainer = styled.div`
  gap: 15px;
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }

  /* General styles for the card container */
  .card-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    //justify-content: space-between;
    border: 1px solid lightgray;
    padding: 10px;
    cursor: pointer;
  }
  .card-container:not(:first-child):last-child {
    border-top:0px;
    height: 53px;
    display: grid;
  }
  .card-main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  //.fp1{
  //  display: flex;
  //  flex-direction: row;
  //  gap: 10px;
  //}


  /* Styles for card details */
  .card-details {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
  }

  /* Styles for the radio button */
  .card-radio {
    display: flex;
    align-items: center;
  }

  /* Styles for the billing name */
  .card-billing-name {
    display: flex;
    align-items: center;
  }
  .card-actions{
    display: flex;
    flex-direction: column;
  }
  .cvv_holder{
    display: flex;
    gap: 5px;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .cvv_holder .cvv_sub_holder{
    display: flex;
    gap:5px;
    width: 70%;
  }
  .cvv_holder .pay_btn{
    width: 120px;
  }
  .cvv_holder_show{
    height: 45px;
    transition: height 0.3s ease-in-out;
  }
  .main_payment_cnt{
    position: relative;
  }
  .loader_ico{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 9%);
    z-index: 1;
  }
  .btn_hght{
    height: 35px;
  }
  .btn_unlink{
    padding:0;
  }

  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
    }
    .card-main{
      flex-direction: column;
    }
    .card-billing-name,.card-actions{
      padding-left: 11%;
    }
    .cvv_holder .cvv_sub_holder{
      width: 100%;
    }
  }
`;
const StyledTitle = styled(Title)`
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

function ExpirationDateInput({ ...props }) {
  const handleChange = (event: any) => {
    let { value } = event.target;
    value = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 2) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
    }
    props.onChange(value);
  };

  return (
    <TextInput
      {...props}
      onChange={handleChange}
      maxLength={5} // MM/YY format
    />
  );
}
